/**
 * Tailwind doesn't have a good way to style search-cancel-button.
 */
bit-search input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 21px;
  width: 21px;
  margin: 0;
  cursor: pointer;
  background-repeat: no-repeat;
  mask-image: url("./close-button.svg");
  -webkit-mask-image: url("./close-button.svg");
  background-color: rgba(var(--color-text-muted));
}

bit-search input[type="search"]::-webkit-search-cancel-button:hover {
  background-color: rgba(var(--color-text-main));
}
