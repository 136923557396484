#duo-frame {
  height: 330px;
  @include themify($themes) {
    background: themed("imgLoading") 0 0 no-repeat;
  }

  iframe {
    border: none;
    height: 100%;
    width: 100%;
  }
}

#web-authn-frame {
  height: 315px;
  @include themify($themes) {
    background: themed("imgLoading") 0 0 no-repeat;
  }

  iframe {
    border: none;
    height: 100%;
    width: 100%;
  }
}

#hcaptcha_iframe {
  border: none;
  transition: height 0.25s linear;
  width: 100%;
}

.list-group-2fa {
  .logo-2fa {
    min-width: 120px;
  }
}

@each $mfaType in $mfaTypes {
  .mfaType#{$mfaType} {
    content: url("../images/two-factor/" + $mfaType + ".png");
    max-width: 120px;
  }
}

.mfaType0 {
  @include themify($themes) {
    content: url("../images/two-factor/0.png");
    max-width: 120px;
    max-height: 62px;
  }
}

.mfaType1 {
  @include themify($themes) {
    content: url("../images/two-factor/1" + themed("mfaLogoSuffix"));
    max-width: 120px;
    max-height: 62px;
  }
}

.mfaType7 {
  @include themify($themes) {
    content: url("../images/two-factor/7" + themed("mfaLogoSuffix"));
    max-width: 120px;
  }
}

.recovery-code-img {
  @include themify($themes) {
    content: url("../images/two-factor/rc" + themed("mfaLogoSuffix"));
    max-width: 100px;
    max-height: 45px;
  }
}

.progress {
  @include themify($themes) {
    background-color: themed("pwStrengthBackground");
  }
}

// Braintree

#bt-dropin-container {
  min-height: 50px;
  @include themify($themes) {
    background: themed("loadingSvg") center center no-repeat;
  }
}

.braintree-placeholder,
.braintree-sheet__header {
  display: none;
}

.braintree-sheet__content--button {
  min-height: 0;
  padding: 0;
  text-align: left;
}

.braintree-sheet__container {
  margin-bottom: 0;
}

.braintree-sheet {
  border: none;
}

// hide duplicate paypal iframe
.braintree-sheet__content--button
  .braintree-sheet__button--paypal
  iframe.zoid-prerender-frame.zoid-invisible {
  display: none !important;
}

[data-braintree-id="upper-container"]::before {
  @include themify($themes) {
    background-color: themed("backgroundColor");
  }
}

.card [data-braintree-id="upper-container"]::before {
  @include themify($themes) {
    background-color: themed("foregroundColor");
  }
}

[data-braintree-id="paypal-button"] {
  @include themify($themes) {
    background-color: themed("backgroundColor");
  }
}

.card [data-braintree-id="paypal-button"] {
  @include themify($themes) {
    background-color: themed("foregroundColor");
  }
}

.paypal-button-text {
  @include themify($themes) {
    color: themed("textColor");
  }
}
