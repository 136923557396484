::-ms-reveal {
  display: none;
}

::placeholder {
  @include themify($themes) {
    color: themed("inputPlaceholderColor");
  }
}

input,
select,
textarea {
  &:required {
    box-shadow: none;
  }
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: -cancel-button;
}

label:not(.form-check-label):not(.btn):not(:has(bit-label)),
label.bold {
  font-weight: 600;
  @include themify($themes) {
    color: themed("textHeadingColor");
  }
}

label.form-check-label,
.form-control-file {
  @include themify($themes) {
    color: themed("textHeadingColor");
  }
}

.form-check-block {
  .form-check-label {
    font-weight: 600;

    > small {
      display: block;
      font-weight: normal;
      @include themify($themes) {
        color: themed("textMuted");
      }
    }

    > span {
      display: block;
      font-weight: normal;
      @extend .mt-2;
    }
  }
}

.form-check-block + .form-check-block {
  &:not(.mt-2) {
    @extend .mt-3;
  }
}

.form-group {
  .form-group-child-check {
    @extend .ml-4;
  }
}

.form-inline {
  input[type="datetime-local"] {
    width: 200px;
  }
}

.form-control {
  @include themify($themes) {
    background-color: themed("inputBackgroundColor");
    border-color: themed("inputBorderColor");
    color: themed("inputTextColor");

    option {
      background-color: themed("backgroundColor");
    }
  }

  &:disabled,
  &[readonly] {
    @include themify($themes) {
      background-color: themed("inputDisabledBackground");
      color: themed("inputDisabledColor");
    }
  }
}

input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
}

.form-control.stripe-form-control:not(.v2) {
  padding-top: 0.55rem;

  &.is-focused {
    outline: 0;
    @include themify($themes) {
      background-color: themed("inputBackgroundColor");
      border-color: themed("inputBorderColor");
      box-shadow: 0 0 0 $input-focus-width
        rgba(mix(color-yiq(themed("primary")), themed("primary"), 15%), 0.5);
      color: themed("inputTextColor");
    }

    &.is-invalid {
      opacity: 0.75;
      @include themify($themes) {
        box-shadow: 0 0 0 $input-focus-width themed("danger");
      }
    }
  }

  &.is-invalid {
    @include themify($themes) {
      border-color: themed("danger");
    }
  }
}

.form-control.stripe-form-control.v2 {
  padding: 0.6875rem 0.875rem;
  border-radius: 0.5rem;
  border-color: rgb(var(--color-text-muted));
  height: unset;
  font-weight: 500;
  color: rgb(var(--color-text-main));
  background-color: rgb(var(--color-background));

  &:hover {
    border-color: rgb(var(--color-primary-600));
  }

  &.is-focused {
    outline: 0;
    border-color: rgb(var(--color-primary-600));
  }

  &.is-invalid {
    color: rgb(var(--color-text-main));
    border-color: rgb(var(--color-danger-600));
  }
}

.dropdown-menu,
.dropdown-item {
  @include themify($themes) {
    background-color: themed("dropdownBackground");
    color: themed("dropdownTextColor");
  }
}

.dropdown-item {
  @include themify($themes) {
    color: themed("dropdownTextColor");
  }

  &.text-danger {
    @include themify($themes) {
      color: themed("danger") !important;
    }
  }
  &:hover {
    @include themify($themes) {
      background-color: themed("dropdownHover");
    }
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
}

.dropdown-menu {
  button {
    cursor: pointer;
  }
  @include themify($themes) {
    border: 1px solid themed("listItemBorder");
  }
}

.list-group-item {
  &:focus,
  &.focus {
    z-index: 100;
  }
  @include themify($themes) {
    background-color: themed("foregroundColor");
    border-color: themed("listItemBorder");
    color: themed("textColor");
  }
  > .two-factor-content {
    justify-content: center;
    flex-direction: row;
    display: flex;
    > .text-col {
      flex-direction: column;
      flex: 1;
    }
    > .logo-col {
      min-width: 100px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: fit-content;
      }
    }
    > .btn-col {
      width: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.list-group-item.active {
  font-weight: bold !important;
  padding-left: calc(#{$list-group-item-padding-x} - 3px);
  @include themify($themes) {
    border-color: themed("borderColor");
    border-left: 3px solid themed("borderPrimaryColor");
    color: themed("listItemActive");
  }
}

.section-header {
  h3,
  .btn.btn-link {
    @include themify($themes) {
      color: themed("headingColor");
    }
  }

  h3 {
    font-weight: normal;
    text-transform: uppercase;
  }
}

.error-inline {
  @include themify($themes) {
    color: themed("danger");
  }
}

// Theming for invalid form elements in the SSO Config Form only
// Will be deprecated by component-level styling in the Component Library
app-org-manage-sso form {
  .form-control.ng-invalid,
  app-input-text.ng-invalid .form-control,
  app-select.ng-invalid .form-control {
    @include themify($themes) {
      border-color: themed("danger");
    }
  }
}

// Browser specific icons overlayed on input fields. e.g. caps lock indicator on password field
::-webkit-calendar-picker-indicator,
input::-webkit-caps-lock-indicator,
input::-webkit-credentials-auto-fill-button {
  @include themify($themes) {
    filter: themed("browserInputIconsFilter");
  }
}
